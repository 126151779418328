<template>
	<div
		:class="['quadro', { 'has-main-image--right': data.isMainImageRight }]"
	>
		<h2 class="quadro-heading" v-text="data.title" />
		<NuxtLink
			:to="data.linkUrl"
			:title="data.title"
			class="quadro-main"
			@click.native="$track('quadro', { category: data })"
		>
			<figure class="quadro-main-inner has-objectfit">
				<picture>
					<source
						media="(min-width: 768px)"
						:data-srcset="
							$dataSrc({
								url: data.image.src,
								size: 'desktop',
								isHiRes: true,
								extension: 'webp',
								hash: data.image.hash,
							})
						"
						type="image/webp"
					/>
					<source
						media="(min-width: 429px)"
						:data-srcset="
							$dataSrc({
								url: data.image.src,
								size: 'tablet',
								isHiRes: true,
								extension: 'webp',
								hash: data.image.hash,
							})
						"
						type="image/webp"
					/>
					<source
						media="(min-width: 1px)"
						:data-srcset="
							$dataSrc({
								url: data.image.src,
								size: 'mobile',
								isHiRes: true,
								extension: 'webp',
								hash: data.image.hash,
							})
						"
						type="image/webp"
					/>
					<source
						media="(min-width: 768px)"
						:data-srcset="
							$dataSrc({
								url: data.image.src,
								size: 'desktop',
								isHiRes: true,
								hash: data.image.hash,
							})
						"
						type="image/jpeg"
					/>
					<source
						media="(min-width: 429px)"
						:data-srcset="
							$dataSrc({
								url: data.image.src,
								size: 'tablet',
								isHiRes: true,
								hash: data.image.hash,
							})
						"
						type="image/jpeg"
					/>
					<source
						media="(min-width: 1px)"
						:data-srcset="
							$dataSrc({
								url: data.image.src,
								size: 'mobile',
								isHiRes: true,
								hash: data.image.hash,
							})
						"
						type="image/jpeg"
					/>
					<LazyImg
						:alt="data.title"
						:data-src="
							$dataSrc({
								url: data.image.src,
								size: 'desktop',
								hash: data.image.hash,
							})
						"
						data-object-position="center center"
						class="quadro-main-image is-objectfit"
					/>
				</picture>
			</figure>
		</NuxtLink>
		<div class="quadro-categories">
			<div class="quadro-categories-inner">
				<NuxtLink
					v-for="(category, index) in data.categories"
					:key="`quadro-category--${index}`"
					:to="category.linkUrl"
					class="quadro-category"
					@click.native="$track('quadro', { category })"
				>
					<figure
						class="quadro-category-inner"
						:style="{ backgroundColor: category.backgroundColor }"
					>
						<div class="quadro-category-picture has-objectfit">
							<picture>
								<source
									media="(min-width: 992px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'large',
											isHiRes: false,
											extension: 'webp',
											hash: category.image.hash,
										})
									"
									type="image/webp"
								/>
								<source
									media="(min-width: 768px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'small',
											isHiRes: false,
											extension: 'webp',
											hash: category.image.hash,
										})
									"
									type="image/webp"
								/>
								<source
									media="(min-width: 480px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'large',
											isHiRes: false,
											extension: 'webp',
											hash: category.image.hash,
										})
									"
									type="image/webp"
								/>
								<source
									media="(min-width: 1px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'small',
											isHiRes: false,
											extension: 'webp',
											hash: category.image.hash,
										})
									"
									type="image/webp"
								/>
								<source
									media="(min-width: 992px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'large',
											isHiRes: false,
											extension: 'png',
											hash: category.image.hash,
										})
									"
									type="image/png"
								/>
								<source
									media="(min-width: 768px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'small',
											isHiRes: false,
											extension: 'png',
											hash: category.image.hash,
										})
									"
									type="image/png"
								/>
								<source
									media="(min-width: 480px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'large',
											isHiRes: false,
											extension: 'png',
											hash: category.image.hash,
										})
									"
									type="image/png"
								/>
								<source
									media="(min-width: 1px)"
									:data-srcset="
										$dataSrc({
											url: category.image.src,
											size: 'small',
											isHiRes: false,
											extension: 'png',
											hash: category.image.hash,
										})
									"
									type="image/png"
								/>
								<LazyImg
									:alt="category.title"
									:data-src="
										$dataSrc({
											url: category.image.src,
											size: 'large',
											isHiRes: false,
											extension: 'png',
											hash: category.image.hash,
										})
									"
									data-object-fit="contain"
									class="quadro-category-image is-objectfit"
								/>
							</picture>
						</div>
						<figcaption
							class="quadro-category-title"
							v-text="category.title"
						/>
					</figure>
				</NuxtLink>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			data: {
				type: Object,
				default() {
					return {};
				},
			},
		},
		methods: {
			getCategoryImageAlt(category) {
				return this.data.title + ' ' + category.title;
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/quadro.scss';
</style>
