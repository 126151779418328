export default {
	head() {
		return {
			script: [{ type: 'application/ld+json', json: this.ldJson }],
		};
	},
	data() {
		return {
			ldJson: {
				'@context': 'https://schema.org/',
				'@type': 'Organization',
				name: 'BAMBINIFASHION.COM',
				alternateName: 'BFC BAMBINIFASHION.COM GmbH',
				url: process.env.HOST_URL,
				logo: `${process.env.HOST_URL}/favicon-196x196.png`,
				image: `${process.env.CDN_URL}/static/images/about/bambinifashion-store-1--desktop.jpg`,
				description:
					'Shop the latest designer kids clothes, lifestyle accessories and shoes for babies, boys & girls with 240+ luxury brands including Molo Kids, Mayoral Kidswear, Gucci Kids, Kenzo Kidswear, Burberry Kidswear, Dolce & Gabbana Kids, Givenchy Kids and more at BAMBINIFASHION.COM. Worldwide shipping. Easy returns.',
				address: {
					'@type': 'PostalAddress',
					streetAddress:
						'Leonard-Bernstein-Straße 10, 13 OG. – Saturn Tower',
					addressLocality: 'Vienna',
					addressRegion: 'Vienna',
					postalCode: '1220',
					addressCountry: 'Austria',
				},
				contactPoint: {
					'@type': 'ContactPoint',
					telephone: '+43 1 512 89 6218',
					contactType: 'Customer Service',
				},
				sameAs: [
					'https://www.facebook.com/bambinifashionofficial',
					'https://www.instagram.com/bambinifashion',
					'https://www.youtube.com/channel/UCr5ryWRdZ-lTl1vM4IrvZOw',
					'https://www.linkedin.com/company/bambinifashion/',
					'https://www.pinterest.at/bambinifashioncom',
				],
			},
		};
	},
};
