<template>
	<Landing />
</template>

<script>
	import Landing from '@/components/Landing.vue';
	export default {
		components: {
			Landing,
		},
		computed: {
			personalInformation() {
				return this.$store.state.user.personalInformation;
			},
			localization() {
				return this.$store.state.user.localization;
			},
			hash() {
				return this.$store.state.user.hash;
			},
		},
		mounted() {
			this.$track('landing');
		},
	};
</script>
