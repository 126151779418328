<template>
	<div class="landing-container">
		<section
			v-for="(section, index) in landing"
			:key="`landing-section--${index}`"
			class="landing-section"
		>
			<Component
				:is="getComponentName(section)"
				:data="section.data"
				:expanded-on-desktop="false"
			/>
		</section>
		<HtmlContent :data="{ content }" />
	</div>
</template>

<script>
	import Banner from '@/components/Banner.vue';
	import FeaturedCategories from '@/components/FeaturedCategories.vue';
	import Quadro from '@/components/Quadro.vue';
	import useCase from '@/use/case.js';
	import LandingLdJson from '@/mixins/landing-ld-json.js';

	export default {
		components: {
			Banner,
			FeaturedCategories,
			Quadro,
			HtmlContent: () => import('@/components/HtmlContent.vue'),
			IosBannerBottom: () => import('@/components/IosBannerBottom.vue'),
		},
		mixins: [LandingLdJson],
		computed: {
			landing() {
				return this.$store.state.page.content;
			},
			isSafari() {
				return this.$platform?.is?.safari;
			},
			content() {
				return this.$store.state.page.meta.content;
			},
		},
		methods: {
			getComponentName(section) {
				return useCase(section.name, 'pascal');
			},
		},
	};
</script>

<style lang="scss">
	@import '@/assets/scss/components/landing.scss';
</style>
