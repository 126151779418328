var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['quadro', { 'has-main-image--right': _vm.data.isMainImageRight }]},[_c('h2',{staticClass:"quadro-heading",domProps:{"textContent":_vm._s(_vm.data.title)}}),_vm._v(" "),_c('NuxtLink',{staticClass:"quadro-main",attrs:{"to":_vm.data.linkUrl,"title":_vm.data.title},nativeOn:{"click":function($event){return _vm.$track('quadro', { category: _vm.data })}}},[_c('figure',{staticClass:"quadro-main-inner has-objectfit"},[_c('picture',[_c('source',{attrs:{"media":"(min-width: 768px)","data-srcset":_vm.$dataSrc({
							url: _vm.data.image.src,
							size: 'desktop',
							isHiRes: true,
							extension: 'webp',
							hash: _vm.data.image.hash,
						}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 429px)","data-srcset":_vm.$dataSrc({
							url: _vm.data.image.src,
							size: 'tablet',
							isHiRes: true,
							extension: 'webp',
							hash: _vm.data.image.hash,
						}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
							url: _vm.data.image.src,
							size: 'mobile',
							isHiRes: true,
							extension: 'webp',
							hash: _vm.data.image.hash,
						}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 768px)","data-srcset":_vm.$dataSrc({
							url: _vm.data.image.src,
							size: 'desktop',
							isHiRes: true,
							hash: _vm.data.image.hash,
						}),"type":"image/jpeg"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 429px)","data-srcset":_vm.$dataSrc({
							url: _vm.data.image.src,
							size: 'tablet',
							isHiRes: true,
							hash: _vm.data.image.hash,
						}),"type":"image/jpeg"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
							url: _vm.data.image.src,
							size: 'mobile',
							isHiRes: true,
							hash: _vm.data.image.hash,
						}),"type":"image/jpeg"}}),_vm._v(" "),_c('LazyImg',{staticClass:"quadro-main-image is-objectfit",attrs:{"alt":_vm.data.title,"data-src":_vm.$dataSrc({
							url: _vm.data.image.src,
							size: 'desktop',
							hash: _vm.data.image.hash,
						}),"data-object-position":"center center"}})],1)])]),_vm._v(" "),_c('div',{staticClass:"quadro-categories"},[_c('div',{staticClass:"quadro-categories-inner"},_vm._l((_vm.data.categories),function(category,index){return _c('NuxtLink',{key:`quadro-category--${index}`,staticClass:"quadro-category",attrs:{"to":category.linkUrl},nativeOn:{"click":function($event){return _vm.$track('quadro', { category })}}},[_c('figure',{staticClass:"quadro-category-inner",style:({ backgroundColor: category.backgroundColor })},[_c('div',{staticClass:"quadro-category-picture has-objectfit"},[_c('picture',[_c('source',{attrs:{"media":"(min-width: 992px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'large',
										isHiRes: false,
										extension: 'webp',
										hash: category.image.hash,
									}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 768px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'small',
										isHiRes: false,
										extension: 'webp',
										hash: category.image.hash,
									}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 480px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'large',
										isHiRes: false,
										extension: 'webp',
										hash: category.image.hash,
									}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'small',
										isHiRes: false,
										extension: 'webp',
										hash: category.image.hash,
									}),"type":"image/webp"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 992px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'large',
										isHiRes: false,
										extension: 'png',
										hash: category.image.hash,
									}),"type":"image/png"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 768px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'small',
										isHiRes: false,
										extension: 'png',
										hash: category.image.hash,
									}),"type":"image/png"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 480px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'large',
										isHiRes: false,
										extension: 'png',
										hash: category.image.hash,
									}),"type":"image/png"}}),_vm._v(" "),_c('source',{attrs:{"media":"(min-width: 1px)","data-srcset":_vm.$dataSrc({
										url: category.image.src,
										size: 'small',
										isHiRes: false,
										extension: 'png',
										hash: category.image.hash,
									}),"type":"image/png"}}),_vm._v(" "),_c('LazyImg',{staticClass:"quadro-category-image is-objectfit",attrs:{"alt":category.title,"data-src":_vm.$dataSrc({
										url: category.image.src,
										size: 'large',
										isHiRes: false,
										extension: 'png',
										hash: category.image.hash,
									}),"data-object-fit":"contain"}})],1)]),_vm._v(" "),_c('figcaption',{staticClass:"quadro-category-title",domProps:{"textContent":_vm._s(category.title)}})])])}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }